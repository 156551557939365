import { withStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';

export const Slider = withStyles({
  root: {},
  track: {
    display: 'none',
  },
  rail: {
    height: 9,
    background: 'linear-gradient(270deg, #F30B48 13.79%, #FEC072 49.28%, #13C569 86.29%);',
    opacity: 1,
    borderRadius: '4px',
  },
  thumb: {
    height: 40,
    width: 40,
    backgroundColor: 'white',
    marginTop: -15,
    marginLeft: -20,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1) !important',
    '&:focus, &:hover, &:active': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1) !important',
    },
    borderRadius: '50%',
  },
})(MaterialSlider);
