import { Themed } from 'theme-ui';

import { Paragraph } from '../components/core/themed';
import { ProductDetails } from './ProductDetails';

import __ from '../hooks/useI18n';

const styles = {
  indent: {
    variant: 'grids.indent',
    svg: {
      opacity: 0.6,
    },
  },
};

export const ProtectionDetails = (props) => (
  <ProductDetails {...props}>
    <Themed.hr />

    <Paragraph>{__('Deine Arbeitskraft ist Deine wichtigste Ressource. Wenn diese ausfällt, kann Dich dies schnell in den finanziellen Ruin treiben.')}</Paragraph>

    <Themed.hr />

    <Paragraph sx={styles.indent}>
      <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27 6H21V3C21 1.335 19.665 0 18 0H12C10.335 0 9 1.335 9 3V6H3C1.335 6 0.015 7.335 0.015 9L0 25.5C0 27.165 1.335 28.5 3 28.5H27C28.665 28.5 30 27.165 30 25.5V9C30 7.335 28.665 6 27 6ZM18 6H12V3H18V6Z" fill="#31006B" />
      </svg>

      {__('Deine Arbeitskraft ist wertvoll, ich habe Dir bereits errechnet Du wirst über Dein Leben über 1,5 Millionen Euro verdienen. Die Berufsunfähigkeitsversicherung hilft Dir dieses Geld abzusichern.')}
    </Paragraph>

    <Paragraph sx={styles.indent}>
      <svg width="29" height="35" viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1917 0L0 3.395V12.3667C0 22.5585 5.19017 30.9675 13.8832 34.8602L14.1952 35L14.5071 34.8602C23.2807 30.9312 28.5185 22.5227 28.5185 12.3545L28.3772 3.39275L14.1917 0Z" fill="#31006B" />
        <path d="M12.1527 20.0602L8.29624 16.2037L7.01074 17.4892L12.1527 22.6312L23.1712 11.6126L21.8857 10.3271L12.1527 20.0602Z" fill="#D8E7FB" />
      </svg>

      {__('Sie zahlt Dir einen monatlichen Betrag, wenn Du Deinen Beruf nicht mehr ausüben kannst. Der Staat zahlt Dir im Fall, dass Du nicht mehr arbeiten kannst nur 1/4 bis maximal die Hälfte Deines Nettoeinkommens und das auch nur in sehr bestimmten Fällen!')}
    </Paragraph>
  </ProductDetails>
);
