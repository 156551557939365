/* eslint-disable jsx-a11y/anchor-is-valid */
// import GSDevTools from 'gsap/GSDevTools';
import { Flex, Themed } from 'theme-ui';
import { useAnimationAngelBackgroundIntro } from '../components/angel/AngelBackground';
import { Carousel } from '../components/core/Carousel';
import { Link, useGoTo } from '../components/core/router/Link';
import { CTAButton } from '../components/core/themed';
// import { slideDelay } from '../config';
import __ from '../hooks/useI18n';

const styles = {
  container: {
    justifyContent: 'flex-start',
  },
  carousel: {
    mt: '52vh',
    height: 'auto',
    flex: '0 0 auto',
  },
  buttons: {
    position: 'absolute',
    bottom: '7vh',
  },
};

export const Intro = ({ match }) => {
  const steps = [
    {
      children: <Themed.h5>{__('Hallo!')}<br />
        {__('Ich bin Alexis, Dein persönlicher Schutzengel.')}<br />
        {__('Damit die schönen Seiten in Deinem Leben überwiegen, kümmere ich mich um die Risiken.')}</Themed.h5>,
    },
    { children: <Themed.h5>{__('Dafür will ich mit Dir Deinen persönlichen Lebensplan entwickeln, dieser wird auf Deine Präferenzen abgestimmt. Dieser Service ist für Dich kostenfrei und dauert nur einige Minuten!')}</Themed.h5> },
    { children: <Themed.h5>{__('Um Dich optimal beschützen zu können, werde ich Dir gleich einige Fragen stellen, um zu verstehen worauf Du in Deinem Leben besonderen Wert legst.')}</Themed.h5> },
    { children: <Themed.h5>{__('Unter dem Motto “Zeichne Dein Leben” kannst Du auf den folgenden Seiten Deine persönliche Situation erfassen. Klicke dazu einfach auf die Antwortmöglichkeiten. Ich erledige den Rest!')}</Themed.h5> },
    // {
    //   children: <Paragraph>Lass uns loslegen und Dein individuelles Schutzprofil erstellen! <br />
    //     Dieser Service ist für Dich kostenfrei und dauert nur einige Minuten!</Paragraph>,
    // },

  ];

  const goTo = useGoTo();
  const { bubble } = useAnimationAngelBackgroundIntro(steps.length, true);

  // GSDevTools.create({ animation: bubble });

  const onMove = (floatIndex) => {
    bubble.progress(floatIndex*0.8);
  };

  const setIndex = index => {
    goTo(`/intro/${index}`, { from: +match.params.step });
  };

  // const activeButton = +match.params.step >= steps.length -1;
  const activeButton = true;

  // TODO: component will unmount not working, doing in AngelBackground!
  // useEffect(() => () => {
  //   bubble.progress(1);
  //   bubble.pause(1);
  //   bubble.kill();
  // }, []);

  return (
    <Flex variant="flex.colCenter" sx={styles.container}>
      <Carousel
        items={steps}
        sx={styles.carousel}
        id="intro"
        activeIndex={+match.params.step || 0}
        onIndexChange={index => setIndex(index)}
        onMove={a => onMove(a)}
      />
      <CTAButton sx={styles.buttons} active={activeButton}>
        <Link to={activeButton ? '/status' :''}>
          {__('Loslegen!')}
        </Link>
      </CTAButton>
    </Flex>
  );
};
