const userConfigUrl = '/userConfig.json';

async function getDynamicUsers() {
  try {
    if (!window.fetch) {
      throw new Error('FETCH is not available!');
    }
    // NOTE: requires a kind of a polyfill
    const response = await window.fetch(userConfigUrl, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Bad response from server', response.status);
    }
    return response.json();
  } catch (e) {
    console.error('error', e);
    return [];
  }
}

export const defaultUsers = [
  { id: 'c3VwZXJjYXJlMjAyMw==', expires: new Date('2222-02-22') },
];

async function getAllUsers() {
  const users = [
    ...defaultUsers,
    ...await getDynamicUsers(),
  ].map(user => ({
    user: {
      ...user,
      expires: new Date(user.expires),
      role: 'view',
    },
    token: new Date(),
  }));
  return users;
}

function dec(s) {
  // eslint-disable-next-line no-undef
  return atob(s);
}

// TODO: get id removed
export const useSignInUser = () => async ({ id }) => {
  const users = await getAllUsers();
  const found = users
    .find(({ user: { id: i, expires } }) => id === dec(i) && new Date(expires)>=new Date(Date.now()));
  return found || { token: undefined, user: undefined };
};
