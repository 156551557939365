import React from 'react';
import { Link as RRLink, useLocation, useHistory } from 'react-router-dom';

import { useLanguage } from '../../../hooks/useI18n';

export const Link = React.forwardRef(({ children, to, ...props }, ref) => {
  const language = useLanguage();
  const { pathname } = useLocation();
  const link = typeof to === 'function' ? to({ pathname }): to;
  return (to ? (
    <RRLink to={`${link}?lang=${language}`} {...props} style={{ width: '100%' }} ref={ref}>
      {children}
    </RRLink>
  ): children);
});

export const useGoTo = () => {
  const language = useLanguage();
  const { push: goTo } = useHistory();
  return (url, state) => {
    console.log('goto!', url);
    goTo(`${url}?lang=${language}`, state);
  };
};
