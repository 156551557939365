import gsap from 'gsap';
import { useEffect, useRef } from 'react';
import { Box } from 'theme-ui';
import { CTAButton } from '../core/themed';
import { useGoTo } from '../core/router/Link';
import __ from '../../hooks/useI18n';

const styles = {
  container: {
    variant: 'absolute',
  },
  buttons: {
    variant: 'flex.bottomButtons',
    height: '13vh',
    justifyContent: 'center',
  },
};

export const CrystalBubble = ({ link, ...props }) => {
  const containerRef = useRef(null);

  const goTo = useGoTo();

  function submit(direction = 1) {
    if (!link) return;

    const moveAway = {
      y: '70vh',
      ease: 'circ',
    };
    // TOOD: we shall initialize the timeline only once, in an init hook
    const tl = gsap.timeline({
      onComplete: () => goTo(link),
      paused: true,
    })
      .to(`#${containerRef.current.id} > .button-container`, { ...moveAway, duration: 0.8 })
      .to(`#${containerRef.current.id} > svg`, moveAway, '0')
      .to('#property', moveAway, '0')
      .to('#clouds', moveAway, '0')
      .duration(1.2);
    if (direction === -1) {
      // only roll back if the TL hass already run
      if (tl.progress() === 1) {
        tl.reverse(1);
      }
    } else tl.play(0);
  }

  useEffect(() => {
    submit(-1);
    gsap.to(`#${containerRef.current.id} #fog`, {
      rotate: 360,
      repeat: -1,
      duration: 4,
      ease: 'linear',
      transformOrigin: '50% 50%',
    });
    gsap.to(`#${containerRef.current.id} #fog path`, {
      opacity: 1,
      repeat: -1,
      duration: 2,
      ease: 'linear',
      yoyo: 1,
    });
  }, [containerRef]);

  return (
    <Box {...props} sx={{ ...styles.container, ...props.sx }} ref={containerRef} id="crystal-bubble">
      <svg width="214" height="251" viewBox="0 0 214 251" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="107" cy="107" r="104.5" stroke="white" strokeWidth="5" />
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="4" width="205" height="205">
          <circle cx="107.486" cy="106.862" r="101.93" fill="url(#paint0_linear)" />
        </mask>
        <g mask="url(#mask0)">
          <rect opacity="0.8" x="-22.6821" y="-16.7646" width="260.35" height="246.544" fill="url(#paint1_linear)" />
        </g>
        <g mask="url(#mask0)" id="fog">
          <path opacity="0.4" d="M27.1787 86.0119C27.9509 79.0621 30.2675 72.8846 34.9006 66.707" stroke="white" strokeWidth="12.2286" strokeLinecap="round" />
          <path opacity="0.3" d="M24.9419 99.7803C22.5454 131.571 33.3561 159.37 66.1671 178.051" stroke="white" strokeWidth="12.2286" strokeLinecap="round" />
          <path opacity="0.3" d="M143.007 29.6416C169.262 41.2245 192.428 73.6566 192.428 103.772" stroke="white" strokeWidth="12.2286" strokeLinecap="round" />
        </g>
        <path d="M36.4443 219.348C36.4443 205.84 47.3942 194.891 60.9015 194.891H155.615C169.123 194.891 180.072 205.84 180.072 219.348V219.601H36.4443V219.348Z" fill="url(#paint2_linear)" />
        <path d="M9.7832 238.459C9.7832 224.952 20.733 214.002 34.2403 214.002H180.983C194.491 214.002 205.44 224.952 205.44 238.459V250.688H9.7832V238.459Z" fill="url(#paint3_linear)" />
        <defs>
          <linearGradient id="paint0_linear" x1="107.486" y1="4.93262" x2="107.486" y2="208.792" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CFE3FF" />
            <stop offset="1" stopColor="#DFEAF8" />
          </linearGradient>
          <linearGradient id="paint1_linear" x1="107.493" y1="-16.7646" x2="107.493" y2="229.779" gradientUnits="userSpaceOnUse">
            <stop stopColor="#CFE3FF" />
            <stop offset="1" stopColor="#DFEAF8" />
          </linearGradient>
          <linearGradient id="paint2_linear" x1="108.258" y1="194.891" x2="108.258" y2="219.601" gradientUnits="userSpaceOnUse">
            <stop stopColor="#413BD9" />
            <stop offset="1" stopColor="#0500B1" />
          </linearGradient>
          <linearGradient id="paint3_linear" x1="107.612" y1="214.002" x2="107.612" y2="250.688" gradientUnits="userSpaceOnUse">
            <stop stopColor="#413BED" />
            <stop offset="1" stopColor="#1913C5" />
          </linearGradient>
        </defs>
      </svg>

      {/* // TODO: refactor this part to separate component */}
      {link && (
        <Box sx={styles.buttons} className="button-container">
          <CTAButton sx={{ background: '#1913C5' }} onClick={submit} animationDelay={6}>
            {__('In die Zukunft schauen')}
          </CTAButton>
        </Box>
      )}
    </Box>
  );
};
